import { amgApi } from "@/service/axios"

class ClaimsService {
    async getClaims(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claims', params)
            return { data, status }
        } catch (error) {
            console.log("Something went wrong on Get Claims ", error);
            throw error
        }
    }

    async getAdministrators(params) {
        try {
            const { data, status } = await amgApi.post('commons/sellerall/4', params)
            return { data, status }
        } catch (error) {
            console.log('Something went wrong on Get Administrators:', error)
            throw error
        }
    }

    async getClaimFiles(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claim-files', params)
            return { data, status }
        } catch (error) {
            console.log('Something went wrong on Get Claim Files:', error);
            throw error
        }
    }

    async getNewDataClaim(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-new-claim-data', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getDataClaim(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claim-data', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async saveNewClaim(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/save-new-data-claim', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getTypesOfClaim() {
        try {
            const { data, status } = await amgApi.get('/commons/claims/get-types-of-claim')
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getRelationsClaim() {
        try {
            const { data, status } = await amgApi.get('/commons/claims/get-relations-claim')
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getClaimCategories(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claim-categories', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getClaimCards(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claim-cards', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getAttendDetails(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-attend-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getPasswordSupervisor(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-password-supervisor', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async saveNewDataSolution(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/save-new-data-solution', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async updateNewDataSolution(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/update-data-solution', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getClaimBanks() {
        try {
            const { data, status } = await amgApi.get('/commons/claims/get-claim-banks')
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getClaimSolutionSources(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-claim-solution-sources', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getDataSolution(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-data-solution', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async changeAdministrator(body) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/change-administrator', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getCounters(params) {
        try {
            const { data, status } = await amgApi.post('/commons/claims/get-counters', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async setDataClaimAttend(params) {
        try {
            const { data, status } = await amgApi.post('/administration/claims/set-data-claim-attend', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

   
    
}
export default new ClaimsService();