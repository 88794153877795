<template>
  <div>
    <hr>
    <div
      style="text-align: center"
      class="mb-2"
    >
      <span class="h3 font-weight-bolder">DETAILS</span>
    </div>

    <ValidationObserver ref="form">
      <!-- Charges and type -->
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="badge-name-group">
                  <span>{{
                    category_id == 1
                      ? "SOURCE"
                      : category_id == 2
                        ? "TYPE"
                        : "CHARGES"
                  }}</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-select
                v-model="category"
                :options="categories"
                :disabled="isDisabled"
                required
                class="select-icon-none"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="category_id != 1"
          md="4"
        >
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="badge-name-group">
                  <span>{{ category_id == 2 ? "MODE" : "TYPE" }}</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-if="category_id == 3"
                v-model="type"
                :disabled="category"
              />
              <b-form-select
                v-else
                v-model="type"
                :options="modes"
                required
                class="select-icon-none"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <b-button
              size="sm"
              variant="primary"
              :class="alertCounterPending ? 'intermitent' : ''"
              :disabled="!isBloquedAdd || [4, 5].includes(status_claim)"
              @click="addDetail"
            >
              <feather-icon
                icon="PlusIcon"
                size="17"
              />
              ADD
            </b-button>
          </b-form-group>
        </b-col>
        <div
          v-if="alertCounterPending"
          class="invalid-feedback ml-3"
        >
          Please add at least one valid details
        </div>
      </b-row>
      <!-- Dinamic Fields -->

      <div
        v-for="(item, index) in details"
        :key="index"
      >
        <b-row>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <div class="d-flex justify-content-between">
                <span v-if="category_id === 1" class="mr-1">
                  <img
                    v-if="item.category == 1"
                    :src="baseUrl + '/images/icons/iq.png'"
                    alt="iq"
                  >
                  <img
                    v-if="item.category == 2"
                    :src="baseUrl + '/images/icons/pg.png'"
                    alt="pg"
                  >
                  <img
                    v-if="item.category == 3"
                    :src="baseUrl + '/images/icons/mf.png'"
                    alt="mf"
                  >
                  <img
                    v-if="item.category == 4"
                    :src="baseUrl + '/images/icons/report2.ico'"
                    alt="report2"
                  >
                  <img
                    v-if="item.category == 5"
                    :src="baseUrl + '/images/icons/loading.gif'"
                    style="width: 20px; height: 20px"
                    alt="loading"
                  >
                </span>
                <span v-if="category_id === 2" class="mr-1">
                  <tabler-icon
                    v-if="item.category === 1"
                    icon="CurrencyDollarIcon"
                  />
                  <tabler-icon
                    v-if="item.category === 2"
                    icon="BanIcon"
                  />
                  <span
                    v-if="item.aditional"
                    class="font-medium-1"
                  >*</span>
                </span>
                <span v-if="category_id === 3" class="mr-1">
                  <feather-icon
                    v-if="item.category === 1"
                    icon="CalendarEventIcon"
                  />
                  <feather-icon
                    v-if="item.category === 2"
                    icon="NotebookIcon"
                  />
                  <feather-icon
                    v-if="item.category === 3"
                    icon="CreditCardIcon"
                  />
                  <span
                    v-if="item.aditional"
                    class="font-medium-1"
                  >*</span>
                </span>
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text class="badge-name-group">
                        <span>DATE</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <kendo-datepicker
                      id="dateDob"
                      v-model="item.date"
                      class="form-control"
                      :format="'MM/dd/yyyy'"
                      :style="
                        errors[0]
                          ? 'border-color: red !important;border-style: double;'
                          : ''
                      "
                      :disabled="readModal"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="badge-name-group">
                      <span>$</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <money
                    v-model="item.amount"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: '',
                      precision: 2,
                      masked: false,
                    }"
                    class="form-control pl-2"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="badge-name-group">
                      <span>CARDS</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select
                    v-model="item.card"
                    :options="cards"
                    required
                    class="select-icon-none"
                    :class="{ 'border border-danger': errors[0] }"
                    @change="changeCard(item)"
                  />
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="1">
            <b-button
              size="sm"
              variant="flat-danger"
              class="btn-icon"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                @click="deleteDetail(index)"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- Contact && total -->
      <b-row>
        <b-col md="5">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text class="badge-name-group">
                    <span>CONTACT TIME</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select
                  v-model="contactTime"
                  :options="contactOptions"
                  :disabled="isDisabled"
                  required
                  :class="{ 'border border-danger': errors[0] }"
                  class="select-icon-none"
                />
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="2" />
        <b-col md="4">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="badge-name-group">
                  <span>TOTAL</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <money
                :value="sumAmount"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$ ',
                  precision: 2,
                  masked: false,
                }"
                class="form-control pl-2 text-danger"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-row class="class-inline">
            <b-col
              md="3"
              class="class-campo-icon add-class-campo-icon badge-name-group"
              style="border-radius: 10px 10px 0px 0px"
            >
              <span>NOTE II</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12">
          <ValidationProvider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <div class="form-group mt-0">
              <textarea
                v-model="description"
                :disabled="!isBloquedAdd || [4, 5].includes(status_claim) || isDisabled"
                class="rounded form-control"
                :class="{ 'border border-danger': errors[0] }"
              />
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>

    <b-modal
      v-model="showAddCard"
      title="Create New Card"
      header-class="b-vue-modal-header"
      hide-footer
      size="lg"
    >
      <add-card-claim
        :client_account_id="client_account_id"
        @click="closeModalClaims"
      />
    </b-modal>
  </div>
</template>

<script>
// Services
import ClaimsService from '@/views/debt-solution/views/claims/claims.service'

export default {
  components: {},
  props: {
    typeOfClaim: Number,
    claim_id: Number,
    client_account_id: String,
    amount_total: null,
    category_id: Number,
    status_claim: Number,
    isDisabled: Boolean,
  },
  data() {
    return {
      details: [],
      contactTime: '',
      contactOptions: [],
      category: 1,
      type: null,
      categories: [],
      cards: [],
      showAddCard: false,
      description: '',
      modes: [
        {
          value: 'automatic',
          text: 'AUTOMATIC',
        },
        {
          value: 'manual',
          text: 'MANUAL',
        },
      ],
      alertCounterPending: false,
    }
  },
  computed: {
    sumAmount() {
      let total = this.details.reduce((sum, detail) => sum + detail.amount, 0)
      total = Number(total)
      return total.toFixed(2)
    },
    isBloquedAdd() {
      return this.category || (!this.category && this.type)
    },
  },
  watch: {
    async category_id() {
      await this.getCategories()
      await this.getContactTime()
    },
  },
  async created() {
    this.addPreloader()

    await Promise.all([
      this.getCategories(),
      this.getCards(),
      this.getContactTime(),
      this.getDataDetail(),
    ])

    this.removePreloader()
  },
  methods: {
    async getCategories() {
      try {
        const response = await ClaimsService.getClaimCategories({
          category_id: this.category_id,
        })

        if (response.status == 200) {
          this.categories = response.data
          if (this.category_id != 1) {
            const other = {
              text: 'OTHER',
              value: null,
            }
            this.categories.push(other)
          }
        }
      } catch (error) {
        this.removePreloader()

        throw error
      }
    },
    addDetail() {
      const { category } = this
      const aditional = !this.category || this.category_id == 2 ? this.type : null
      const form = {
        category,
        aditional,
        date: '',
        amount: '',
        card: '',
      }
      this.details.push(form)
      this.alertCounterPending = false
    },
    deleteDetail(index) {
      this.details.splice(index, 1)
    },
    async getCards() {
      try {
        this.cards = []

        const response = await ClaimsService.getClaimCards({
          claim_id: this.claim_id,
        })

        if (response.status == 200) {
          response.data.map(card => {
            this.cards.push({
              text: card.card,
              value: card.card,
            })
          })
          const other = {
            text: 'OTHER',
            value: null,
          }
          this.cards.push(other)
        }
      } catch (error) {
        this.removePreloader()

        throw error
      }
    },
    changeCard(item) {
      if (!item.card) {
        item.card = ''
        this.showAddCard = true
      }
    },
    closeModalClaims() {
      this.showAddCard = false
      this.getCards()
    },
    getContactTime() {
      const option1 = ['12 hour', '24 hour', '48 hour']
      const option2 = ['3 hour', '6 hour', '12 hour', '24 hour', '48 hour']
      const data = []
      const options = this.category_id == 2 ? option2 : option1
      options.forEach((option, index) => {
        data.push({
          text: option,
          value: index + 1,
        })
      })
      this.contactOptions = data
    },
    async getDataDetail() {
      try {
        const response = await ClaimsService.getAttendDetails({
          claim_id: this.claim_id,
        })

        if (response.status == 200 && response.data.length > 0) {
          this.$emit('validateDetails', response.data.length)
          response.data.map(detail => {
            this.details.push({
              category: detail.claim_category,
              aditional: detail.claim_aditional,
              date: detail.detail_date,
              amount: detail.amount,
              card: detail.card,
            })
          })
          this.contactTime = response.data[0].contact_time
          this.description = response.data[0].note_2
        }
      } catch (error) {
        this.removePreloader()

        throw error
      }
    },
  },
}
</script>

<style scoped>
.solutionTitle {
  font-weight: 900;
  padding: 13px;
  color: #a98001;
  font-size: 21px;
}

.height-fiel {
  height: 30px;
}

.input-group {
  flex-wrap: nowrap;
}

.x-class {
  height: 20px;
  margin-top: 19px;
}

.intermitent {
  border: 1px solid rgb(170, 6, 6);
  box-shadow: 0px 0px 20px;
}
</style>
