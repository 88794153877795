<template>
  <div>
    <hr style="border: 1px solid #efb911">
    <div
      style="text-align: center"
      class="py-1 px-3"
    >
      <div style="text-align: center">
        <span class="solutionTitle">SOLUTION</span>
      </div>
      <b-button
        v-if="!disabled"
        variant="primary"
        size="sm"
        style="width: 75px; border-radius: 10px !important"
        :class="alertValidateSolution ? 'intermitent' : ''"
        @click="addform"
      >
        ADD
        <i class="fas fa-plus" />
      </b-button>
      <div
        v-if="alertValidateSolution"
        class="invalid-feedback"
      >
        Please add at least one valid details
      </div>
    </div>
    <ValidationObserver ref="form">
      <b-container
        class="pt-0 pb-0"
        style="overflow-y: scroll; max-height: 240px"
      >
        <div>
          <div
            v-for="(item, index) in arrayTypeSolution"
            :key="index"
          >
            <b-row>
              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="item.sources"
                      required
                      style="appearance: none !important"
                      :disabled="disabled"
                      :class="{ 'border border-danger': errors[0] }"
                      @change="getPaymentData(item)"
                    >
                      <b-form-select-option
                        v-for="(option, index) in claimSolutionSources"
                        :key="index"
                        :value="option.id"
                      >{{ option.name }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="item.sources == 6"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="item.transaction"
                      required
                      style="appearance: none !important"
                      :disabled="disabled"
                      :class="{ 'border border-danger': errors[0] }"
                      @change="selectVoid(item)"
                    >
                      <b-form-select-option
                        v-for="(option, index) in voidTransactions"
                        :key="index"
                        :value="option.transaction_id"
                      >{{ option.transaction_id }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="item.sources == 7"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="item.transaction"
                      required
                      style="appearance: none !important"
                      :disabled="disabled"
                      :class="{ 'border border-danger': errors[0] }"
                      @change="selectRefund(item)"
                    >
                      <b-form-select-option
                        v-for="(option, index) in refundTransactions"
                        :key="index"
                        :value="option.transaction_id"
                      >{{ option.transaction_id }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col :md="item.sources == 6 || item.sources == 7 ? 3 : 7">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text class="badge-name-group">
                          <span>$</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <money
                        v-model="item.amount"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '',
                          precision: 2,
                          masked: false,
                        }"
                        class="form-control pl-2"
                        :disabled="
                          disabled || item.sources == 6 || item.sources == 7
                        "
                        :class="{ 'border border-danger': errors[0] }"
                      />
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="!disabled"
                md="1"
                class="p-0"
              >
                <img
                  :src="'/images/delete.png'"
                  style="margin-top: 20px; cursor: pointer"
                  @click="deleteform(index, item.solution_id)"
                  alt="delete"
                >
              </b-col>
            </b-row>
            <b-row v-if="item.sources == 5">
              <b-col md="1" />
              <b-col md="5">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-input-group
                      prepend="BANK"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-select
                        v-model="item.bank"
                        required
                        style="appearance: none !important"
                        :disabled="disabled"
                      >
                        <b-form-select-option
                          v-for="(option, index) in claimBanks"
                          :key="index"
                          :value="option.id"
                          :class="{ 'border border-danger': errors[0] }"
                        >{{ option.name }}</b-form-select-option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-input-group
                      prepend="ACCOUNT"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      <b-form-input
                        id="inline-form-input-username"
                        v-model="item.num_account"
                        v-mask="'####-#####-###-####'"
                        :class="{ 'border border-danger': errors[0] }"
                        :disabled="disabled"
                      />
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
      <hr style="border: 1px solid #cccac7">
      <b-container class="pt-0 mb-1">
        <b-row>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="badge-name-group">
                      <span>TOTAL</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <money
                    v-model.lazy="sumAmount"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    }"
                    class="form-control pl-2 text-danger"
                    :class="{ 'border border-danger': errors[0] }"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <b-row class="class-inline">
              <b-col
                md="4"
                class="input-group-text time-group"
              >
                <span>TIME</span>
              </b-col>
              <b-col
                md="8"
                class="p-0"
              >
                <b-input-group>
                  <b-form-select
                    v-model="contactTime"
                    :options="contactOptions"
                    required
                    class="select-icon-none"
                    :disabled="editfieldDisabled || isDisabled"
                  />
                  <b-input-group-append
                    is-text
                    style="cursor: pointer"
                    @click="editField()"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row class="class-inline">
              <b-col md="3">
                <span>NOTE</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="!disabled"
            md="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <div class="form-group">
                <b-form-textarea
                  v-model="commentary"
                  class="rounded form-control"
                  :class="{ 'border border-danger': errors[0] }"
                />
              </div>
            </ValidationProvider>
          </b-col>
          <b-col
            v-if="disabled"
            md="12"
          >
            <div class="form-group">
              <div
                class="rounded form-control h-auto"
                v-html="commentary"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </ValidationObserver>
  </div>
</template>
<script>
import { Money } from 'v-money'

// Services
import ClaimsService from '@/views/debt-solution/views/claims/claims.service'

export default {
  components: {
    Money,
  },
  props: {
    claimId: String, category_id: Number, client_account_id: String, isDisabled: Boolean,
  },
  data() {
    return {
      arrayTypeSolution: [],
      contactOptions: [
        {
          text: '3 hour',
          value: '3 hour',
        },
        {
          text: '6 hour',
          value: '6 hour',
        },
        {
          text: '12 hour',
          value: '12 hour',
        },
        {
          text: '24 hour',
          value: '24 hour',
        },
        {
          text: '48 hour',
          value: '48 hour',
        },
        {
          text: '72 hour',
          value: '72 hour',
        },
      ],
      claimSolutionSources: [],
      claimBanks: [],
      contactTime: '72 hour',
      commentary: '',
      disabled: false,
      editfieldDisabled: true,
      voidTransactions: [],
      refundTransactions: [],
      alertValidateSolution: false,
    }
  },
  computed: {
    sumAmount() {
      const totall = this.arrayTypeSolution.reduce(
        (sum, total) => sum + total.amount,
        0,
      )
      return totall
    },
  },
  mounted() {
    //
  },
  async created() {
    this.addPreloader()

    await Promise.all([
      this.getDataSolution(),
      this.getClaimBanks(),
      this.getClaimSolutionSources(),
    ])

    this.removePreloader()
  },
  methods: {
    editField() {
      this.editfieldDisabled = !this.editfieldDisabled
    },
    async saveSolution() {
      try {
        const validate = await this.$refs.form.validate()

        if (validate) {
          const confirm = await this.showGenericConfirmSwal({})

          if (confirm.value) {
            this.addPreloader()

            const response = await ClaimsService.saveNewDataSolution({
              idsolution: this.claimId,
              datasolution: this.arrayTypeSolution,
              total: this.sumAmount,
              time: this.contactTime,
              commentary: this.commentary,
            })

            if (response.status == 200) {
              this.showGenericSwal({ text: 'Approved' })
            }

            thi.removePreloader()
          }
        }
      } catch (error) {
        this.removePreloader()
        throw error
      }
    },
    async updateSolution() {
      try {
        const validate = await this.$refs.form.validate()

        if (validate) {
          const confirm = await this.showGenericConfirmSwal({})

          if (confirm.value) {
            this.addPreloader()

            const response = await ClaimsService.updateNewDataSolution({
              idsolution: this.claimId,
              time: this.contactTime,
            })

            if (response.status == 200) {
              this.showGenericSwal({ text: 'Approved' })
            }

            this.removePreloader()
          }
        }
      } catch (error) {
        this.removePreloader()

        throw error
      }
    },
    addform() {
      const form = {
        sources: this.category_id == 1 ? 1 : 2,
        amount: '',
        transaction: null,
        merchant: null,
        bank: 1,
        num_account: '',
        solution_id: null,
      }
      this.arrayTypeSolution.push(form)
      this.alertValidateSolution = false
    },
    deleteform(index, solution_id) {
      this.arrayTypeSolution.splice(index, 1)
    },
    async getClaimBanks() {
      try {
        const response = await ClaimsService.getClaimBanks()

        if (response.status == 200) {
          this.claimBanks = response.data
        }
      } catch (error) {
        throw error
      }
    },
    async getClaimSolutionSources() {
      try {
        const response = await ClaimsService.getClaimSolutionSources({
          category_id: this.category_id,
        })

        if (response.status == 200) {
          this.claimSolutionSources = response.data
        }
      } catch (error) {
        throw error
      }
    },
    async getDataSolution() {
      try {
        const response = await ClaimsService.getDataSolution({
          idsolution: this.claimId,
        })

        if (response.status == 200) {
          if (response.data) {
            this.arrayTypeSolution = response.data
            this.contactTime = response.data[0].time
            this.commentary = response.data[0].commentary

            this.disabled = response.data.length > 0
          }
        }
      } catch (error) {
        throw error
      }
    },
    getPaymentData(item) {
      if (item.sources == 6 || item.sources == 7) {
        // axios
        //   .post("/api/claims/get-claim-payments", {
        //     account_id: this.client_account_id,
        //     source_id: item.sources,
        //   })
        //   .then((response) => {
        //     if (item.sources == 6) {
        //       this.voidTransactions = response.data;
        //     } else {
        //       this.refundTransactions = response.data;
        //     }
        //   });
      }
    },
    selectRefund(item) {
      const data_ref = this.refundTransactions.find(
        refund => refund.transaction_id == item.transaction,
      )
      item.amount = data_ref.amount
      item.merchant = data_ref.merchant
    },
    selectVoid(item) {
      const data_voidp = this.voidTransactions.find(
        voidp => voidp.transaction_id == item.transaction,
      )
      item.amount = data_voidp.amount
      item.merchant = data_voidp.merchant
    },
  },
}
</script>

<style>
.solutionTitle {
  font-weight: 900;
  padding: 13px;
  color: #a98001;
  font-size: 21px;
}
.intermitent {
  border: 1px solid rgb(170, 6, 6);
  box-shadow: 0px 0px 20px;
}
.time-group .input-group-text {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
