<template>
  <b-modal
    modal
    title="SUPERVISOR PASSWORD"
    v-model="show"
    size="lg"
    id="modal-supervisor-password"
    modal-class="modal-primary"
    title-tag="h3"
    hide-footer
    @hidden="close()"
    :no-close-on-backdrop="true"
    scrollable
  >
    <ValidationObserver ref="form">
      <div class="container">
        <b-row>
          <b-col md="12">
            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group class="mt-1">
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>PASSWORD</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    class="class-campo-text w-90"
                    v-model="fieldPass"
                    type="password"
                    :class="{ 'border border-danger': errors[0] }"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12" style="text-align: center" class="mt-3">
            <b-button variant="primary" @click="verifyPassword">
              VERIFY PASSWORD
            </b-button>
          </b-col>
        </b-row>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
// Services
import ClaimsService from "@/views/debt-solution/views/claims/claims.service";

export default {
  data() {
    return {
      show: false,

      getPassSuper: "",
      fieldPass: "",
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async verifyPassword() {
      try {
        const validate = await this.$refs.form.validate();

        if (validate) {
          const response = await ClaimsService.getPasswordSupervisor({
            module_id: this.moduleId,
            field_pass: this.fieldPass,
            type: 1,
          });

          if (response.status == 200) {
            if (response.data) {
              this.getPassSuper = response.data;

              if (this.getPassSuper == "ok") {
                this.showGenericSuccessSwal({ text: "Correct Password" });
                this.close();
              } else {
                this.showGenericSuccessSwal({
                  title: "Error",
                  text: "Incorrect Password",
                });
              }
            }
          }
        }
      } catch (error) {
        console.log("Error on verify Password ", error);
        throw error;
      }
    },
    close(verify) {
      this.$emit("onClose", verify);
    },
  },
  created() {
    this.show = true;
  },
};
</script>

<style scoped>
</style>
