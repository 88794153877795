var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr'),_vm._m(0),_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v(_vm._s(_vm.category_id == 1 ? "SOURCE" : _vm.category_id == 2 ? "TYPE" : "CHARGES"))])])],1),_c('b-form-select',{staticClass:"select-icon-none",attrs:{"options":_vm.categories,"disabled":_vm.isDisabled,"required":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1)],1),(_vm.category_id != 1)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v(_vm._s(_vm.category_id == 2 ? "MODE" : "TYPE"))])])],1),(_vm.category_id == 3)?_c('b-form-input',{attrs:{"disabled":_vm.category},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_c('b-form-select',{staticClass:"select-icon-none",attrs:{"options":_vm.modes,"required":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('b-button',{class:_vm.alertCounterPending ? 'intermitent' : '',attrs:{"size":"sm","variant":"primary","disabled":!_vm.isBloquedAdd || [4, 5].includes(_vm.status_claim)},on:{"click":_vm.addDetail}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"17"}}),_vm._v(" ADD ")],1)],1)],1),(_vm.alertCounterPending)?_c('div',{staticClass:"invalid-feedback ml-3"},[_vm._v(" Please add at least one valid details ")]):_vm._e()],1),_vm._l((_vm.details),function(item,index){return _c('div',{key:index},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.category_id === 1)?_c('span',{staticClass:"mr-1"},[(item.category == 1)?_c('img',{attrs:{"src":_vm.baseUrl + '/images/icons/iq.png',"alt":"iq"}}):_vm._e(),(item.category == 2)?_c('img',{attrs:{"src":_vm.baseUrl + '/images/icons/pg.png',"alt":"pg"}}):_vm._e(),(item.category == 3)?_c('img',{attrs:{"src":_vm.baseUrl + '/images/icons/mf.png',"alt":"mf"}}):_vm._e(),(item.category == 4)?_c('img',{attrs:{"src":_vm.baseUrl + '/images/icons/report2.ico',"alt":"report2"}}):_vm._e(),(item.category == 5)?_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":_vm.baseUrl + '/images/icons/loading.gif',"alt":"loading"}}):_vm._e()]):_vm._e(),(_vm.category_id === 2)?_c('span',{staticClass:"mr-1"},[(item.category === 1)?_c('tabler-icon',{attrs:{"icon":"CurrencyDollarIcon"}}):_vm._e(),(item.category === 2)?_c('tabler-icon',{attrs:{"icon":"BanIcon"}}):_vm._e(),(item.aditional)?_c('span',{staticClass:"font-medium-1"},[_vm._v("*")]):_vm._e()],1):_vm._e(),(_vm.category_id === 3)?_c('span',{staticClass:"mr-1"},[(item.category === 1)?_c('feather-icon',{attrs:{"icon":"CalendarEventIcon"}}):_vm._e(),(item.category === 2)?_c('feather-icon',{attrs:{"icon":"NotebookIcon"}}):_vm._e(),(item.category === 3)?_c('feather-icon',{attrs:{"icon":"CreditCardIcon"}}):_vm._e(),(item.aditional)?_c('span',{staticClass:"font-medium-1"},[_vm._v("*")]):_vm._e()],1):_vm._e(),_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("DATE")])])],1),_c('kendo-datepicker',{staticClass:"form-control",style:(errors[0]
                        ? 'border-color: red !important;border-style: double;'
                        : ''),attrs:{"id":"dateDob","format":'MM/dd/yyyy',"disabled":_vm.readModal},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("$")])])],1),_c('money',_vm._b({staticClass:"form-control pl-2",class:{ 'border border-danger': errors[0] },model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}},'money',{
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    precision: 2,
                    masked: false,
                  },false))],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("CARDS")])])],1),_c('b-form-select',{staticClass:"select-icon-none",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.cards,"required":""},on:{"change":function($event){return _vm.changeCard(item)}},model:{value:(item.card),callback:function ($$v) {_vm.$set(item, "card", $$v)},expression:"item.card"}})],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"1"}},[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"flat-danger"}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteDetail(index)}}})],1)],1)],1)],1)}),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("CONTACT TIME")])])],1),_c('b-form-select',{staticClass:"select-icon-none",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.contactOptions,"disabled":_vm.isDisabled,"required":""},model:{value:(_vm.contactTime),callback:function ($$v) {_vm.contactTime=$$v},expression:"contactTime"}})],1)],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}}),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("TOTAL")])])],1),_c('money',_vm._b({staticClass:"form-control pl-2 text-danger",attrs:{"value":_vm.sumAmount,"disabled":""}},'money',{
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
                masked: false,
              },false))],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"class-inline"},[_c('b-col',{staticClass:"class-campo-icon add-class-campo-icon badge-name-group",staticStyle:{"border-radius":"10px 10px 0px 0px"},attrs:{"md":"3"}},[_c('span',[_vm._v("NOTE II")])])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"form-group mt-0"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"rounded form-control",class:{ 'border border-danger': errors[0] },attrs:{"disabled":!_vm.isBloquedAdd || [4, 5].includes(_vm.status_claim) || _vm.isDisabled},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})])]}}])})],1)],1)],2),_c('b-modal',{attrs:{"title":"Create New Card","header-class":"b-vue-modal-header","hide-footer":"","size":"lg"},model:{value:(_vm.showAddCard),callback:function ($$v) {_vm.showAddCard=$$v},expression:"showAddCard"}},[_c('add-card-claim',{attrs:{"client_account_id":_vm.client_account_id},on:{"click":_vm.closeModalClaims}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"h3 font-weight-bolder"},[_vm._v("DETAILS")])])}]

export { render, staticRenderFns }