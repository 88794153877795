<template>
  <b-modal
    v-model="uploadFileModal"
    title="Upload File"
    modal-class="modal-primary"
    body-class="p-50"
    button-size="sm"
    ok-title="Ok"
    :hide-footer="files.length === 0"
    centered
    hide-footer
    @hidden="actionOnHideUploadFileModal"
  >
    <drag-and-drop
      v-model="files"
      :files-array="files"
    />

  </b-modal>
</template>

<script>

import DragAndDrop from '@/views/commons/utilities/DragAndDrop'
import {mapGetters, mapMutations} from 'vuex'

export default {

  components: {
    DragAndDrop,

  },

  data() {
    return {

      uploadFileModal: false,

      files: [],
    }
  },
  computed: {
    ...mapGetters({
      uploadFilesClaim: 'DebtSolutionClients/G_UPLOAD_FILES_CLAIM',
      skin: 'appConfig/skin',
    }),

  },
 created(){
    this.uploadFileModal = true
   if(this.uploadFilesClaim.length>0){
     this.files = this.uploadFilesClaim
   }
 },
  methods: {

    ...mapMutations({
      showModalTaskToday: "DebtSolutionClients/SET_UPLOAD_FILES_CLAIM",

    }),
    actionOnHideUploadFileModal() {
      this.showModalTaskToday(this.files)
      this.$emit('close')

    },

    openUploadFileMoadl() {
      this.uploadFileModal = true
    },

    deleteFile(content) {
      const index = this.currentFiles.indexOf(content)
      if (index > -1) this.currentFiles.splice(index, 1)
    },

  },
}
</script>
