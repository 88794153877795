var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticStyle:{"border":"1px solid #efb911"}}),_c('div',{staticClass:"py-1 px-3",staticStyle:{"text-align":"center"}},[_vm._m(0),(!_vm.disabled)?_c('b-button',{class:_vm.alertValidateSolution ? 'intermitent' : '',staticStyle:{"width":"75px","border-radius":"10px !important"},attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addform}},[_vm._v(" ADD "),_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(_vm.alertValidateSolution)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please add at least one valid details ")]):_vm._e()],1),_c('ValidationObserver',{ref:"form"},[_c('b-container',{staticClass:"pt-0 pb-0",staticStyle:{"overflow-y":"scroll","max-height":"240px"}},[_c('div',_vm._l((_vm.arrayTypeSolution),function(item,index){return _c('div',{key:index},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{class:{ 'border border-danger': errors[0] },staticStyle:{"appearance":"none !important"},attrs:{"required":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.getPaymentData(item)}},model:{value:(item.sources),callback:function ($$v) {_vm.$set(item, "sources", $$v)},expression:"item.sources"}},_vm._l((_vm.claimSolutionSources),function(option,index){return _c('b-form-select-option',{key:index,attrs:{"value":option.id}},[_vm._v(_vm._s(option.name))])}),1)],1)]}}],null,true)})],1),(item.sources == 6)?_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{class:{ 'border border-danger': errors[0] },staticStyle:{"appearance":"none !important"},attrs:{"required":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.selectVoid(item)}},model:{value:(item.transaction),callback:function ($$v) {_vm.$set(item, "transaction", $$v)},expression:"item.transaction"}},_vm._l((_vm.voidTransactions),function(option,index){return _c('b-form-select-option',{key:index,attrs:{"value":option.transaction_id}},[_vm._v(_vm._s(option.transaction_id))])}),1)],1)]}}],null,true)})],1):_vm._e(),(item.sources == 7)?_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{class:{ 'border border-danger': errors[0] },staticStyle:{"appearance":"none !important"},attrs:{"required":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.selectRefund(item)}},model:{value:(item.transaction),callback:function ($$v) {_vm.$set(item, "transaction", $$v)},expression:"item.transaction"}},_vm._l((_vm.refundTransactions),function(option,index){return _c('b-form-select-option',{key:index,attrs:{"value":option.transaction_id}},[_vm._v(_vm._s(option.transaction_id))])}),1)],1)]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":item.sources == 6 || item.sources == 7 ? 3 : 7}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("$")])])],1),_c('money',_vm._b({staticClass:"form-control pl-2",class:{ 'border border-danger': errors[0] },attrs:{"disabled":_vm.disabled || item.sources == 6 || item.sources == 7},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}},'money',{
                        decimal: '.',
                        thousands: ',',
                        prefix: '',
                        precision: 2,
                        masked: false,
                      },false))],1)],1)]}}],null,true)})],1),(!_vm.disabled)?_c('b-col',{staticClass:"p-0",attrs:{"md":"1"}},[_c('img',{staticStyle:{"margin-top":"20px","cursor":"pointer"},attrs:{"src":'/images/delete.png',"alt":"delete"},on:{"click":function($event){return _vm.deleteform(index, item.solution_id)}}})]):_vm._e()],1),(item.sources == 5)?_c('b-row',[_c('b-col',{attrs:{"md":"1"}}),_c('b-col',{attrs:{"md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"prepend":"BANK"}},[_c('b-form-select',{staticStyle:{"appearance":"none !important"},attrs:{"required":"","disabled":_vm.disabled},model:{value:(item.bank),callback:function ($$v) {_vm.$set(item, "bank", $$v)},expression:"item.bank"}},_vm._l((_vm.claimBanks),function(option,index){return _c('b-form-select-option',{key:index,class:{ 'border border-danger': errors[0] },attrs:{"value":option.id}},[_vm._v(_vm._s(option.name))])}),1)],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"prepend":"ACCOUNT"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####-#####-###-####'),expression:"'####-#####-###-####'"}],class:{ 'border border-danger': errors[0] },attrs:{"id":"inline-form-input-username","disabled":_vm.disabled},model:{value:(item.num_account),callback:function ($$v) {_vm.$set(item, "num_account", $$v)},expression:"item.num_account"}})],1)],1)]}}],null,true)})],1)],1):_vm._e()],1)}),0)]),_c('hr',{staticStyle:{"border":"1px solid #cccac7"}}),_c('b-container',{staticClass:"pt-0 mb-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"badge-name-group"},[_c('span',[_vm._v("TOTAL")])])],1),_c('money',_vm._b({staticClass:"form-control pl-2 text-danger",class:{ 'border border-danger': errors[0] },attrs:{"disabled":""},model:{value:(_vm.sumAmount),callback:function ($$v) {_vm.sumAmount=$$v},expression:"sumAmount"}},'money',{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$ ',
                    precision: 2,
                    masked: false,
                  },false))],1)],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',{staticClass:"class-inline"},[_c('b-col',{staticClass:"input-group-text time-group",attrs:{"md":"4"}},[_c('span',[_vm._v("TIME")])]),_c('b-col',{staticClass:"p-0",attrs:{"md":"8"}},[_c('b-input-group',[_c('b-form-select',{staticClass:"select-icon-none",attrs:{"options":_vm.contactOptions,"required":"","disabled":_vm.editfieldDisabled || _vm.isDisabled},model:{value:(_vm.contactTime),callback:function ($$v) {_vm.contactTime=$$v},expression:"contactTime"}}),_c('b-input-group-append',{staticStyle:{"cursor":"pointer"},attrs:{"is-text":""},on:{"click":function($event){return _vm.editField()}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"class-inline"},[_c('b-col',{attrs:{"md":"3"}},[_c('span',[_vm._v("NOTE")])])],1)],1),(!_vm.disabled)?_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('b-form-textarea',{staticClass:"rounded form-control",class:{ 'border border-danger': errors[0] },model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})],1)]}}],null,false,1151928172)})],1):_vm._e(),(_vm.disabled)?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"rounded form-control h-auto",domProps:{"innerHTML":_vm._s(_vm.commentary)}})])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"solutionTitle"},[_vm._v("SOLUTION")])])}]

export { render, staticRenderFns }