<template>
  <b-modal
    id="modal-new-claim"
    v-model="show"
    modal
    :title="title"
    size="lg"
    modal-class="modal-primary"
    title-tag="h3"
    :no-close-on-backdrop="true"
    scrollable
    :hide-footer="!isFromAccount && isDisabled"
    @hidden="close"
  >
    <b-container class="mt-1">
      <b-row class="mt-1 px-">
        <!-- Program -->
        <b-col cols="6">
          <h5 class="text-left">Client Name</h5>
          <p
            class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
          >
            {{ claim.nameClient }}
          </p>
        </b-col>
        <!-- Client -->
        <b-col v-if="!isNewClaim" cols="6">
          <h5 class="text-left">Claim Code</h5>
          <p
            class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
          >
            {{ claim.code }}
          </p>
        </b-col>
      </b-row>

      <br />
      <ValidationObserver ref="form">
        <b-row>
          <b-col class="md-6">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>ACCOUNT</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="claim.account"
                      type="text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="applicant"
                  rules="required"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100">
                          <span>APPLICANT</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model.trim="claim.nameApplicant"
                        type="text"
                        :readonly="disableApplicant"
                        :class="{ 'border border-danger': errors[0] }"
                      />
                      <b-input-group-append
                        is-text
                        style="cursor: pointer"
                        @click="changeApplicant"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="!disableRelation || (!isNewClaim && claim.relation)"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="relation"
                  rules="required"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>RELATION</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select
                        v-model.trim="claim.relation"
                        required
                        style="appearance: none !important"
                        :class="{ 'border border-danger': errors[0] }"
                      >
                        <b-form-select-option
                          v-for="(option, index) in relationsClaim"
                          :key="index"
                          :value="option.id"
                          >{{ option.relation }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>ADVISOR</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="claim.advisorName"
                      type="text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>ADMIN</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="claim.adminName"
                      type="text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="md-6">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="number"
                  rules="required|mobile"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>NUMBER</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model.trim="claim.mobileApplicant"
                        v-mask="'(###) ###-####'"
                        class="class-campo-text w-90"
                        :readonly="disableMobile"
                        :disabled="isDisabled"
                        :style="errors[0] ? 'border-color: red!important' : ''"
                        :class="errors[0] ? 'border border-danger ' : ''"
                      />

                      <b-input-group-append
                        is-text
                        style="cursor: pointer"
                        @click="disableMobile = !disableMobile"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>EMAIL</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model.trim="claim.emailApplicant"
                        :disabled="isDisabled"
                        class="class-campo-text w-90"
                        :readonly="disableEmail"
                        :class="{ 'border border-danger': errors[0] }"
                      />
                      <b-input-group-append
                        is-text
                        style="cursor: pointer"
                        @click="disableEmail = !disableEmail"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col v-if="showMore" md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>SNN</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="claim.ssn_itin"
                      class="class-campo-text w-90"
                      readonly
                      type="number"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="showMore" md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>DOB</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <kendo-datepicker
                      id="dateDob"
                      v-model="claim.dob"
                      style="width: 16.3em !important"
                      class="claims-datepicker"
                      :format="'MM/dd/yyyy'"
                      :disabled="true"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="!isNewClaim && !showMore" md="12">
                <b-row class="class-inline">
                  <b-col
                    md="4"
                    offset-md="8"
                    class="fix-padding-check mt-2 text-center clickable"
                    @click="showMore = !showMore"
                  >
                    <b-button variant="primary" size="sm">
                      <feather-icon icon="PlusIcon" size="16" />
                      VIEW
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="showMore" class="mb-3">
          <b-col md="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="address"
              rules="required"
            >
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend class="w-17">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>ADDRESS</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="claim.address"
                    class="class-campo-text w-90"
                    :readonly="!disableAddress"
                    :disabled="isDisabled"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                  <b-input-group-append
                    is-text
                    style="cursor: pointer"
                    @click="disableAddress = !disableAddress"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col class="md-6">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>TYPE OF CLAIM</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="claim.type"
                      :disabled="detailsLenght > 0 || isDisabled"
                      :readonly="detailsLenght > 0"
                      required
                      style="appearance: none !important"
                      @change="alterType"
                    >
                      <b-form-select-option
                        v-for="(option, index) in typeClaims"
                        :key="index"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>CONTACT TIME</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="claim.contactTime"
                      :disabled="isDisabled"
                      required
                      style="appearance: none !important"
                    >
                      <b-form-select-option
                        v-for="(option, index) in contactTimes"
                        :key="index"
                        :value="option.id"
                        >{{ option.name }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="md-6">
            <b-row>
              <b-col md="12">
                <b-row class="class-inline">
                  <b-col md="6" offset-md="4" class="fix-padding-check">
                    <div class="checkbox-style-div">SEND SMS</div>
                  </b-col>
                  <b-col md="2" style="margin: auto 0">
                    <b-form-checkbox v-model="checkSms" size="lg" disabled />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row class="class-inline">
                  <b-col md="6" offset-md="4" class="fix-padding-check">
                    <div class="checkbox-style-div">SEND EMAIL</div>
                  </b-col>
                  <b-col md="2" style="margin: auto 0">
                    <b-form-checkbox
                      v-model="checkEmail"
                      size="lg"
                      :disabled="isDisabled"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="3" offset-md="3" class="text-center">
            <b-button
              v-if="isNewClaim"
              style="margin-right: 10px"
              variant="primary"
              @click="openUploadFiles"
            >
              ADD FILES
            </b-button>
            <span v-if="uploadFilesClaim.length > 0">
              <b-img v-bind="mainProps" rounded="circle" alt="Circle image" />
              <span class="total-files">{{ uploadFilesClaim.length }}</span>
            </span>
          </b-col>
          <b-col md="5" style="margin: auto 0">
            <b-row class="class-inline mt-0">
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="amount"
                  rules="required|validate-amount"
                >
                  <b-form-group style="margin-top: 0.2rem">
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>AMOUNT</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <money
                        v-model.trim="claim.amount"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$',
                          precision: 2,
                          masked: false,
                        }"
                        class="form-control"
                        style="
                          width: 65%;
                          height: 34px;
                          border: 1px #d1d1d1 solid;
                          border-radius: 0 4px 4px 0;
                          padding-left: 5px;
                        "
                        :class="{
                          'border-danger': errors[0] && vmoneyValidate,
                          'bg-danger': !isNewClaim,
                        }"
                        :style="
                          isDarkSkin
                            ? 'background: #17171A;color: white'
                            : 'background: white;'
                        "
                        :disabled="!isNewClaim"
                      />
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col md="12">
            <b-row class="class-inline">
              <b-col md="5" class="header-description">
                <span>DESCRIPTION</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <div class="form-group mt-0">
                <textarea
                  v-model="claim.description"
                  class="form-control"
                  style="border-radius: 0px 10px 10px 10px"
                  :disabled="!isNewClaim"
                  :class="{ 'border-danger': errors[0] }"
                />
              </div>
            </ValidationProvider>
          </b-col>
        </b-row> </ValidationObserver
      ><!-- Just for Administration -->
      <claim-details
        v-if="isSecondPartShow"
        ref="secondPart"
        :type-of-claim="claim.type"
        :claim_id="claim_id"
        :client_account_id="accountId"
        :category_id="category_id"
        :amount_total="claim.amount"
        :status_claim="status_claim"
        :is-disabled="isDisabled"
        @validateDetails="validateDetails"
      />

      <claim-solution
        v-if="isSolutionShow"
        ref="solutionPart"
        :category_id="category_id"
        :claim-id="claim_id"
        :client_account_id="accountId"
        :is-disabled="isDisabled"
      />

      <modal-upload-files v-if="showModalFiles" @close="closeUploadFiles" />

      <modal-supervisor-password
        v-if="showModalPasswordSupervisor"
        @click="closeModalPassSuper"
      />
    </b-container>

    <template #modal-footer>
      <div v-if="isFromAccount">
        <b-row v-if="isNewClaim">
          <div style="margin: auto">
            <b-button variant="primary" @click="saveClaim">
              <feather-icon icon="SaveIcon" style="margin-right: 3px" /> Save
            </b-button>
          </div>
        </b-row>
      </div>
      <div v-else>
        <b-button
          v-if="status_claim !== 3 && status_claim !== 2"
          variant="warning"
          @click="pendingSave(2)"
        >
          PENDING</b-button
        >
        <b-button
          v-if="status_claim !== 3"
          variant="info"
          class="ml-1"
          @click="pendingSave(3)"
        >
          IN PROCESS</b-button
        >
        <b-button
          v-if="status_claim === 3"
          variant="success"
          class="ml-1"
          @click="solvedClaim(4)"
        >
          SOLVED</b-button
        >
        <b-button variant="danger" class="ml-1" @click="pendingSave(5)">
          CLOSED</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// Services
import ClaimsService from "@/views/debt-solution/views/claims/claims.service";

// Components
import ClaimDetails from "./ClaimDetails.vue";
import ClaimSolution from "./ClaimSolution.vue";
import ModalSupervisorPassword from "./ModalSupervisorPassword.vue";
import ModalUploadFiles from "./ModalUploadFiles.vue";

export default {
  components: {
    ClaimDetails,
    ClaimSolution,
    ModalUploadFiles,
    ModalSupervisorPassword,
  },
  props: {
    accountId: String,
    isNewClaim: Boolean,
    claim_id: Number,
    category_id: Number,
    iduser: Number,
    claimData: null,
    status_claim: Number,
    isFromAccount: Boolean,
  },
  data() {
    return {
      show: false,
      checkSms: true,
      checkEmail: false,
      claim: {
        nameClient: null,
        nameApplicant: null,
        account: null,
        relation: null,
        advisorId: 1,
        advisorName: null,
        adminId: 1,
        adminName: null,
        mobileApplicant: "",
        emailApplicant: "",
        type: 1,
        contactTime: 1,
        amount: "",
        description: "",
        code: null,
        dob: "",
        address: "",
        ssn_i: null,
        language: "",
        lead_id: "",
      },
      disableApplicant: true,
      disableMobile: true,
      disableEmail: true,
      disableRelation: true,
      disableAddress: false,
      showMore: false,
      files: [],
      images: [],
      showModalFiles: false,
      typeClaims: [],
      relationsClaim: [],
      contactTimes: [],
      initialData: {},
      tab: "",

      vmoneyValidate: false,
      title: "",
      // RED CIRCLE--NUMBER OF FILES
      mainProps: {
        blank: true,
        blankColor: "red",
        width: 25,
        height: 25,
        class: "m1 circle-files ",
      },
      detailsLenght: 0,
      showModalPasswordSupervisor: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      uploadFilesClaim: "DebtSolutionClients/G_UPLOAD_FILES_CLAIM",
    }),
    isSecondPartShow() {
      return (
        (this.status_claim === 2 &&
          [1, 2].includes(this.currentUser.role_id === 1)) ||
        [3, 4, 5].includes(this.status_claim) ||
        ([1, 2, 3, 4, 5].includes(this.status_claim) && this.moduleId == 4)
      );
    },
    isDisabled() {
      return (
        (this.moduleId !== 4 || this.tab === 4) && this.isFromAccount === false
      );
    },
    isSolutionShow() {
      return (
        (this.status_claim == 3 &&
          [1, 2].includes(this.currentUser.role_id == 1)) ||
        this.status_claim == 4 ||
        ((this.status_claim == 3 || this.status_claim == 4) &&
          this.moduleId == 4)
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    if (!this.isFromAccount) {
      this.title = this.claimData.claim_type.toUpperCase();
    } else if (this.isNewClaim == true) {
      this.title = "CREATE NEW CLAIM";
    } else {
      this.title = "CHARGES";
    }

    this.addPreloader();
    this.tab = this.$route.meta.tab;
    await Promise.all([
      this.getClaimData(),
      this.getTypeClaims(),
      this.getContactTimes(),
      this.getRelationsClaim(),
    ]);

    this.removePreloader();
    this.show = true;
  },
  methods: {
    ...mapMutations({
      showModalTaskToday: "DebtSolutionClients/SET_UPLOAD_FILES_CLAIM",
    }),
    openUploadFiles() {
      this.showModalFiles = true;
    },
    closeUploadFiles() {
      this.showModalFiles = false;
    },
    async pendingSave(status) {},
    async solvedClaim(status) {},
    closeModalPassSuper(verify) {
      this.showModalPasswordSupervisor = false;
      if (verify) {
        this.axiosSaveClaim();
      }
    },
    validateDetails(lenght) {
      this.detailsLenght = lenght;
    },
    alterType() {
      this.category_id = this.claim.type;
    },
    closeModalClaims() {
      this.$emit("click");
    },
    async getClaimData() {
      try {
        const service = this.isNewClaim ? "getNewDataClaim" : "getDataClaim";

        const response = await ClaimsService[service]({
          account_id: this.accountId,
          claim_id: this.claim_id,
        });

        if (response.status == 200) {
          this.initialData = response.data[0];
          this.claim.nameClient = response.data[0].client_name;
          this.claim.nameApplicant = response.data[0].client_name;
          this.claim.account = response.data[0].account;
          this.claim.adminName = response.data[0].admin_name;
          this.claim.advisorName = response.data[0].advisor_name;
          this.claim.mobileApplicant = response.data[0].client_number;
          this.claim.emailApplicant = response.data[0].client_email;
          this.claim.advisorId = response.data[0].id_advisor;
          this.claim.adminId = response.data[0].id_admin;
          this.claim.dob = response.data[0].dob;
          this.claim.address = response.data[0].address;
          this.claim.ssn_itin = response.data[0].ssn_itin;
          this.claim.language = response.data[0].language;
          this.claim.lead_id = response.data[0].lead_id;
          if (!this.isNewClaim) {
            this.claim.relation = response.data[0].relation;
            this.claim.type = response.data[0].claim_type;
            this.claim.contactTime = response.data[0].contact_time;
            this.claim.nameClient = response.data[0].client_real;
            this.claim.amount = response.data[0].amount;
            this.claim.description = response.data[0].claim_description;
            this.claim.code = response.data[0].code;
            this.claim.ssn_itin = response.data[0].ssn_itin
              ? response.data[0].ssn
              : response.data[0].itin;
            this.claim.language = response.data[0].language;
            this.claim.lead_id = response.data[0].lead_id;
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async saveClaim() {
      try {
        this.vmoneyValidate = true;
        const validate = await this.$refs.form.validate();

        if (validate) {
          const confirm = await this.showGenericConfirmSwal({
            text: "The data will be save!",
          });

          if (confirm.value) {
            const formdata = new FormData();
            this.uploadFilesClaim.forEach((file) => {
              formdata.append("files[]", file, file.name);
            });
            formdata.append("account_id", this.accountId);
            formdata.append("data_claim", JSON.stringify(this.claim));
            formdata.append("checkemail", this.checkEmail);
            formdata.append("module_id", this.moduleId);
            formdata.append("created_by", this.currentUser.user_id);

            this.addPreloader();

            const response = await ClaimsService.saveNewClaim(formdata);

            if (response.status == 200) {
              this.showGenericToast({ text: "Approved" });
              this.$emit("refresh");
              this.close();
            }

            this.removePreloader();
          }
        }
      } catch (error) {
        this.removePreloader();

        throw error;
      }
    },
    async axiosSaveClaim() {
      try {
        const formdata = new FormData();
        this.uploadFilesClaim.forEach((file) => {
          formdata.append("files[]", file, file.name);
        });
        formdata.append("account_id", this.accountId);
        formdata.append("data_claim", JSON.stringify(this.claim));
        formdata.append("checkemail", this.checkEmail);
        formdata.append("module_id", this.moduleId);
        formdata.append("created_by", this.currentUser.user_id);

        this.addPreloader();

        const response = await ClaimsService.saveNewClaim(formdata);

        if (response.status == 200) {
          this.showGenericToast({ title: "Approved" });
          this.close();
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();

        throw error;
      }
    },
    async getTypeClaims() {
      try {
        const response = await ClaimsService.getTypesOfClaim();

        if (response.status == 200) {
          this.typeClaims = response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    async getRelationsClaim() {
      try {
        const response = await ClaimsService.getRelationsClaim();

        if (response.status === 200) {
          this.relationsClaim = response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    getContactTimes() {
      // Dont move this fields
      const times = [
        "30 MINUTES",
        "1 HOUR",
        "6 HOURS",
        "12 HOURS",
        "24 HOURS",
        "NOW",
      ];
      const data = [];
      times.forEach((time, index) => {
        data.push({
          id: index + 1,
          name: time,
        });
      });
      this.contactTimes = data;
    },
    changeApplicant() {
      // Change Aplicant
      if (this.disableApplicant) {
        this.disableApplicant = false;
      } else {
        this.disableApplicant = true;
      }

      // Relation with Client
      if (this.claim.nameApplicant != this.initialData.client_name) {
        this.disableRelation = false;
      } else {
        this.disableRelation = true;
        this.claim.relation = "";
      }
    },
    close() {
      const files = [];
      this.showModalTaskToday(files);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
#modal-new-claim {
  .fix-padding-check {
    padding: 1px 2px 1px 9px;
  }
  .badge-name {
    font-size: 13px;
    margin-bottom: 0px;
    color: #8d8d8d;
  }

  .name-style {
    font-size: 17px;
    color: #1976d2;
  }

  .code-style {
    font-size: 17px;
    color: #dfb15a;
  }

  .checkbox-style-div {
    pointer-events: none !important;
    background: #00b050 !important;
    padding: 5px 18px;
    border-radius: 5px !important;
    width: 115px;
    color: #fff;
  }

  .class-inline {
    margin-top: 5px;
  }
  .btn-primary {
    border-radius: 5px !important;
  }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #dfb15a;
    border-color: #dfb15a;
  }
  .w-35 {
    width: 35% !important;
  }
  .w-17 {
    width: 17% !important;
  }

  .total-files {
    font-size: 14px;
    font-weight: bold;
    color: white;
    position: relative;
    right: -9px;
    top: 1px;
  }
  .circle-files {
    position: absolute;
    top: 6px;
  }

  .header-description {
    border-radius: 8px 8px 0px 0px;
    width: 200px;
    height: 34px;
    border: 1px #d1d1d1 solid;
    border-bottom: none;
    padding-top: 7px;
    margin-left: 14px;
  }

  .picker-select-date {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #fff !important;
    .claims-datepicker {
      height: 40px !important;
      border: none !important;
    }
    input::placeholder {
      color: #ccccd3;
      font-weight: 400;
    }
  }
  .dark-layout .picker-select-date {
    background: #17171a !important;
    input::placeholder {
      color: #4f4f52 !important;
      font-weight: 600;
    }
  }
}
</style>
